import { css } from '@emotion/react'
import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {
    Grid,
    Typography,
    Snackbar,
    Container,
    Fab    
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CardProgres from '../elements/CardProgres'
import ModalJumio from '../elements/ModalJumio.compilable'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import CircularProgress from '@material-ui/core/CircularProgress';
import { withThemeProps } from '@findep/microfronts-core'
import { sortBy, uniqBy, reverse } from 'lodash'
import { navigate } from "../../helpers/queryNavigate"
import { TubeService } from '../../services/LandingV4/tube'
import {        
    getTaskUrl,
    getTaskActionText,
    getSpecialStatusCase,
    getOrderArray,
    getSpecialVeriAFI

} from '../../helpers/tubeTask'

import formatNumber from '../../helpers/formatNumber'
import jumioRedirect from '../../helpers/jumioRedirect'
import buttonOrange from '../../assets/viewtube/button-naranja.png'
import buttonOrangeMobil from '../../assets/viewtube/button-naranja-mobil.png'
import { getPathById } from "../../helpers/array"

function TuProceso({
    pageContext,
    amountLabel,
    paymentLabel,
    errorServiceMessage,
    title,
    subtitle,
    nextButtonText,
    jumioModalTitle,
}) {    

    const TextHeader = styled.div`
        color: primary;
        text-align: center;
        font-weight: bold;
        font-size: 1.2em;
    `
    const buttonCss = css`
        display: flex;
        justify-content: flex-center;
    `       
    const cssAmount = css`    
        position: relative;    
        margin: auto;
        border-radius:50px;
        /* border: 1px solid #999; */
        padding:13px;
        width:100%;
            
        /* background-image:  url(${buttonOrange}); */

        @media(min-width: 350px ){
            background: linear-gradient(#000, #ddd);
            background:url(${buttonOrangeMobil});
            background-size: 100% 100%;
            background-repeat:no-repeat;    
            background-size: cover;
            height:50px;
        }
        @media(min-width: 540px ){      
            background: linear-gradient(#000, #ddd);      
            background:url(${buttonOrange});
            background-size: 100% 100%;
            background-repeat:no-repeat;    
            background-size: cover;
            height:100px;
        }
    `
    const posFloat = css`
        display: flex;
        position: relative;    
        color: white;
        /* font-weight: bold; */
        @media(min-width: 350px ){
        font-size: 16px;
        margin-left   : 15px;
            margin-top   : 2px;
        }
        @media(min-width: 540px ){
            font-size: 24px;
            margin-left   : 50px;
            margin-top   : 27px;
        }
    `
 
    const [tasks, setTasks] = useState([])
    const [creditApplicationId, setId] = useState('')
    const [flujoState, setFlujo] = useState({})
    const [status, setStatus] = useState('')
    const [errorService, setErrorService] = useState(false)
    const [errorCode, setErrorCode] = useState()    
    const [modal, setModal] = useState(false)
    const [langs, setLangs] = useState('es')
    const [originEdo,setOriginEdo]=useState('TX')
    const [tasks_active, setTaskActive] = useState([
        "verifyemail",
        "verifybureau",
        "verifyafi",
        "verifymobilenumber",
        "veridocingresoafi",
        "verifyidentitywithbiometrics",
        "verifysignature",
        "verifyidentitywithvideoconference",
        "veriempleo",
        "veridomicilio",
        "verifycreditpolicies",
        "veripresencial"
    ])
    
    useEffect(() => {
        const fetch = async () => {

            let params = new URLSearchParams(window.location.search);
            var errorCode = params.get('errorCode');
            if (errorCode !== null) {
                console.log("contract", errorCode);
                setErrorCode(errorCode)
                setModal(true)
            }

            let flujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
            let id = flujo?.creditApplicationId

            let Tube = new TubeService(id)
            setLangs(pageContext.locale === "enUS" ? "en" : "es")
            const { data } = await Tube.sendLang(pageContext.locale === "enUS" ? "en" : "es")            
            const { forceRedirect,originState } = data
            
            setOriginEdo(originState)
            
            if (forceRedirect !== null && forceRedirect !== undefined) {
                navigate(forceRedirect)
            }

            if (!flujo.creditApplicationId) {
                flujo.creditApplicationId = id
            }

            // const uniq = sortBy(
            //                     uniqBy(
            //                             reverse(data.tasks), (obj) => obj.kind
            //                         ), ['title']
            //                     ).map(
            //                             el => {
            //                                 const status = getSpecialStatusCase(el.status, el.kind) || 
            //                                                         (['new', 'needscorrection'].includes(el.status?.toLowerCase()) ? flujo.tubo?.[el.kind]?.toLowerCase() || 
            //                                                         el.status?.toLowerCase() : el.status?.toLowerCase()
            //                                                     )

            //                             return {
            //                                 ...el,
            //                                 status
            //                             }
            //                     })

            const uniq = sortBy(data.tasks, (obj) => obj.kind
                    , ['status']
                ).map(
                        el => {
                            const status = getSpecialStatusCase(el.status, el.kind) || 
                                                    (['new', 'needscorrection'].includes(el.status?.toLowerCase()) ? flujo.tubo?.[el.kind]?.toLowerCase() || 
                                                    el.status?.toLowerCase() : el.status?.toLowerCase()
                                                )

                        return {
                            ...el,
                            status
                        }
                })

                const uniq_ = sortBy(uniq, (obj) => obj.description
                    , ['title']
                ).map(
                        el => {
                            const name=el.description.replace(/\s+/g, '')  
                            const kind = getSpecialVeriAFI( el.kind,name) || 
                                                    (['VeriRefePersonal'].includes(el.kind?.toLowerCase()) ? flujo.tubo?.[el.kind]?.toLowerCase() || 
                                                    el.kind?.toLowerCase() : el.kind?.toLowerCase()
                                                )                            
                            
                        return {
                            ...el,
                            kind
                        }
                })

            let obj= await data.tasks.filter(result => result.kind === 'VeriRefePersonal').map((item) =>{
                const name=item.description.replace(/\s+/g, '')                  
                return `VeriRefePersonal_${name}`
            })

            await obj.map((item)=>tasks_active.push(item))

            const preOferta = {
                oferta: {
                    payment: data.payment,
                    amount: data.amount,
                    period: data.paymentPeriod,
                    terms: data.terms,
                    nextPayment: data.nextPayment,
                }
            }

            flujo.preOferta = flujo.preOferta || preOferta

            flujo.preOferta.oferta = { ...flujo.preOferta.oferta, ...preOferta.oferta }

            setTasks(getOrderArray(uniq_))
            setId(id)
            setFlujo(flujo)
            setStatus(data.status)

            sessionStorage.setItem('flujo', JSON.stringify(flujo))

            // console.log("uniq_=>",uniq_)
        }

        fetch()
            .catch((e) => {
                console.error(e)
                setErrorService(true)
            })

        
        if (status?.toUpperCase() === 'APPROVED') {
            // if(originEdo ==='CA'){
            //     navigate(pageContext?.next?.path)
            // }else{
            //     let navigateUrl1 = getPathById(pageContext.errorPath, 'old')
            //     navigate(navigateUrl1)
            // }
            let navigateUrl1 = getPathById(pageContext.errorPath, 'old')
            navigate(navigateUrl1)
            
        } else if (status?.toUpperCase() === 'REJECTED' || status?.toUpperCase() === 'CANCELLED') {
            let navigateUrl = getPathById(pageContext.errorPath, 'legacy')
            // navigate(navigateUrl)
        }
    }, [])

    useEffect(() => {        
        if (status?.toUpperCase() === 'APPROVED') {
            // if(originEdo ==='CA'){
            //     navigate(pageContext?.next?.path)
            // }else{
            //     let navigateUrl1 = getPathById(pageContext?.errorPath, 'old')
            //     navigate(navigateUrl1)
            // }
            let navigateUrl1 = getPathById(pageContext.errorPath, 'old')
                navigate(navigateUrl1)
        } else if (status?.toUpperCase() === 'REJECTED' || status?.toUpperCase() === 'CANCELLED') {
            let navigateUrl = getPathById(pageContext.errorPath, 'legacy')
            // navigate(navigateUrl)
        }
    }, [status])

    const closeModal = (valor) => {
        setModal(valor)
        jumioRedirect()
    }

    const nextPage = () => {
        let navigateUrl
        switch (status?.toUpperCase()) {
            case 'APPROVED':
                navigateUrl = pageContext?.next?.path
                break
            case 'REJECTED':
                navigateUrl = pageContext?.next?.path
                break
        }

        navigate(navigateUrl)
    }

    return (
        <Container justify="center" maxWidth="sm">
            <Snackbar open={errorService} autoHideDuration={6000} onClose={() => setErrorService(false)}>
                <Alert
                    variant="filled"
                    severity="warning"
                    onClose={() => setErrorService(false)}
                >
                    {errorServiceMessage}
                </Alert>
            </Snackbar>
            <Grid container  justify="center" alignItems="center" spacing={3}>
                <br/>&nbsp;

                <Grid item xs={12}>                    
                    <div css={cssAmount}>                        
                        <span css={posFloat}>
                            <ArrowForwardIcon/> &nbsp;&nbsp;&nbsp;                         
                            {amountLabel}: {`${formatNumber(flujoState ? flujoState.preOferta ? flujoState.preOferta.oferta.amount : 0 : 0)}`}                         
                        </span> 
                    </div>
                </Grid>

                <Grid item xs={12}>                    
                    <div css={cssAmount}>                        
                        <span css={posFloat}>
                            <ArrowForwardIcon/> &nbsp;&nbsp;&nbsp;
                            {paymentLabel}: {`${formatNumber(flujoState ? flujoState.preOferta ? flujoState.preOferta.oferta.payment : 0 : 0)}`}                         
                        </span> 
                    </div>
                </Grid>
                
                <Grid item xs={12}>
                <Typography align="center">
                    <TextHeader>{title}</TextHeader>
                </Typography>
                </Grid>             
                
                <Grid item xs={12}>                
                    <Typography style={{ color: "#3F6377" }} align="center">
                        {subtitle}
                    </Typography>                
                </Grid>
                <br />

              <Grid item xs={12}>
                
                {tasks.length === 0 ? <CircularProgress /> :
                    <Fragment>
                        {
                            
                            tasks.map((task, index) => {
                                let task_active= tasks_active.filter((taskn)=>taskn === task.kind)
                                
                                // const task_active= tasks_active.map((item,index)=>(
                                //     task.kind === item.toLowerCase() && return (item)
                                // ))
                                
                                // const status = getSpecialStatusCase(task.status, task.kind) || (['new','needscorrection'].includes(task.status?.toLowerCase()) ? flujoState.tubo?.[task.kind]?.toLowerCase() || task.status?.toLowerCase() : task.status?.toLowerCase())
                                // if (task.kind !== "VeridocDomAFI" && task.kind !== "VeridocIdentiAFI" && task.kind !== "VerifyCreditPolicies") {                                    
                                //  console.log("task.kind=>",task.kind, "<->",task_active[0])

                                if (task.kind === task_active[0]) {
                                    const status = task.status
                                    return (
                                        <CardProgres
                                            key={task.kind}
                                            // firstItem={index === 0}
                                            lastItem={index + 1 === tasks.length}                                                                                        
                                            title={task.title}
                                            body={task.description}
                                            textAction={getTaskActionText(task.kind, langs)}
                                            status={status?.toLowerCase()}
                                            onChange={() => navigate(`${getTaskUrl(task.kind)}`)}
                                            id={index}
                                            kindTask={task.kind}
                                        />
                                    )
                                }
                            }
                            )
                        }

                    </Fragment>
                }
                </Grid>
                <br />
                <Grid item >
                    <div css={buttonCss} >
                        <Fab 
                            id="tu-proceso-finalizar-prestamo-button"
                            variant="extended" 
                            size="medium"  
                            aria-label="add"
                            onClick={nextPage}
                            disabled={!['APPROVED'].includes(status?.toUpperCase())}                              
                            style={!['APPROVED'].includes(status?.toUpperCase())?
                                    {
                                        borderRadius: 35,
                                        backgroundColor:"#E3DDDD",                                       
                                        padding: "18px 36px",
                                        fontSize: "18px",
                                        color: "grey"
                                    } :
                                    {
                                        borderRadius: 35,
                                        backgroundColor:"#000",
                                        backgroundImage: "linear-gradient(#000, #9B9898)",
                                        padding: "18px 36px",
                                        fontSize: "18px",
                                        color: "white"
                                    }
                                }
                        >
                            {nextButtonText}
                            <ArrowForwardIcon sx={{ mr: 1 }} />                            
                        </Fab>                        
                    </div>
                </Grid>
                <br />
                <br />
                &nbsp;

                



            </Grid>
            <br />            
            <ModalJumio title={jumioModalTitle} errorCode={errorCode} open={modal} onChange={() => { closeModal(false) }} />
        </Container>

    )
}

TuProceso.propTypes = {
    image: PropTypes
}

TuProceso.defaultProps = {
    amountLabel: 'Monto',
    paymentLabel: 'Pago',
    errorServiceMessage: 'Lo sentimos, por el momento el servicio no se encuentra disponible',
    title: 'Progreso de mi solicitud',
    subtitle: 'Tienes acciones pendientes en tu solicitud de préstamo. Visita cada paso para completarlo.',
    nextButtonText: 'FINALIZAR PROCESO',
    jumioModalTitle: 'Error de verificación',
}

export default withThemeProps(TuProceso, 'VDNPTuProceso')